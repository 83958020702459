<template>
  <div class="auth-page" v-if="tokenExistente == null">
    <div class="container-fluid p-0">
      <div class="row g-0 align-items-center">
        <div class="col-xxl-4 col-lg-4 col-md-6">
          <div class="row justify-content-center g-0">
            <div class="col-xl-9">
              <div class="card mb-0">
                <div class="card-body">
                  <div class="auth-full-page-content rounded d-flex p-3 my-2">
                    <div class="w-100">
                      <div class="d-flex flex-column h-100">
                        <div class="mb-5 mb-md-5">
                          <div class="d-block auth-logo">
                            <img 
                              src="@/assets/img/logo-argusblack.png" alt="Logo argusblack"
                              height="50" class="auth-logo-dark me-start"
                            />
                            <img
                              src="@/assets/img/logo-argusblack-blanco.png" alt="Logo argusblack"
                              height="50" class="auth-logo-light me-start"
                            />
                          </div>
                        </div>
                        <div class="auth-content my-auto">
                          <div class="text-center">
                            <h5 class="mb-0">Bienvenido a ArgusBlack</h5>
                            <p class="text-muted mt-2">
                              Administra tu ISP desde un solo lugar.
                            </p>
                            <p><strong>Entra y conócelo.</strong></p>
                          </div>
                          <div class="mt-5 pt-3" role="form">
                            <div class="form-floating form-floating-custom mb-4">
                              <input
                                type="text" class="form-control"
                                id="identidad" placeholder="Identidad"
                                required v-model="identidad"
                              />
                              <label for="identidad">Identidad</label>
                              <div class="form-floating-icon">
                                <eva-icon name="people-outline"></eva-icon>
                              </div>
                            </div>
                            <div class="form-floating form-floating-custom mb-4 auth-pass-inputgroup">
                              <input
                                :type="type" class="form-control pe-5"
                                id="clave" placeholder="Clave"
                                required="" v-model="clave"
                                @keyup.enter="abrir()"
                              />
                              <button
                                id="clave-addon" type="button"
                                class="btn btn-link position-absolute h-100 end-0 top-0"
                                @click="type == 'text' ? (type = 'password') : (type = 'text')"
                              >
                                <i class="mdi mdi-eye-outline font-size-18 text-muted"></i>
                              </button>
                              <label for="password-input">Clave</label>
                              <div class="form-floating-icon">
                                <eva-icon name="lock-outline"></eva-icon>
                              </div>
                            </div>

                            <!-- <div class="mb-3">
                              <center>
                                <vue-recaptcha
                                  ref="recaptcha"
                                  @verify="onVerify"
                                  @expired="onExpired"
                                  :sitekey="sitekey">
                                </vue-recaptcha>
                              </center>
                            </div> -->

                            <div class="mb-3">
                              <button
                                class="btn btn-primary w-100 waves-effect waves-light"
                                type="button" :disabled="procesando" @click="abrir()"
                              >
                                {{ procesando ? "Validando..." : "Entrar" }}
                              </button>
                            </div>
                          </div>

                          <div class="mt-4 pt-3 text-center">
                            <router-link :to="{ name: 'recuperarCuenta' }">
                              <small>¿Olvidaste tu clave?</small>
                            </router-link>
                            <br />
                            <a href="https://livaur.com/software/argusblack">
                              <small>¿Aun no tienes una cuenta?</small>
                            </a>
                          </div>
                        </div>
                        <div class="mt-4 text-center">
                          <p class="mb-0">
                            <img
                              src="../assets/img/logo-hecho-en-mexico.png"
                              class="bx-pull-right"
                            />
                            <small>
                              <b>ArgusBlack</b> es mantenido por
                              <a href="https://livaur.com" target="_blank">Livaur</a>
                              {{ anio }}
                            </small>
                            <br />
                            <small>Versión: <strong>{{ version }}</strong></small>
                          </p>
                        </div>

                        <br v-if="visibleModo" />

                        <div class="rounded overflow-hidden text-center" v-if="visibleModo">
                          <div class="bg-warning bg-gradient p-1">
                            <h5 class="my-2 text-white">
                              {{ modoDes == "development" ? "Development" : "" }}
                              {{ modoDes == "testing" ? "Testing" : "" }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end auth full page content -->
        </div>
        <!-- end col -->
        <div class="col-xxl-8 col-lg-8 col-md-6 d-none d-sm-block">
          <div class="auth-bg bg-white py-md-5 p-4 d-flex">
            <div class="bg-overlay bg-white"></div>
            <!-- end bubble effect -->
            <div class="row justify-content-center align-items-center">
              <div class="col-xl-8">
                <div class="mt-4">
                  <img src="@/assets/img/login-img.png" class="img-fluid" alt="Login imagen" />
                </div>
                <div class="p-0 p-sm-4 px-xl-0 py-5">
                  <div
                    id="reviewcarouselIndicators"
                    class="carousel slide auth-carousel"
                    data-bs-ride="carousel"
                  >
                    <div class="carousel-indicators carousel-indicators-rounded">
                      <button
                        type="button"
                        data-bs-target="#reviewcarouselIndicators"
                        data-bs-slide-to="0"
                        class="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#reviewcarouselIndicators"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#reviewcarouselIndicators"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                      ></button>
                    </div>
                    <!-- end carouselIndicators -->
                    <div class="carousel-inner w-75 mx-auto">
                      <div class="carousel-item active">
                        <div class="testi-contain text-center">
                          <h5 class="font-size-20 mt-4">Usa múltiples servicios</h5>
                          <p class="font-size-15 text-muted mt-3 mb-0">
                            Puedes usar múltiples líneas de internet en el mismo router y
                            definir qué clientes saldrán por alguno de esos servicios de
                            internet.
                          </p>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="testi-contain text-center">
                          <h5 class="font-size-20 mt-4">Sistema Cloud</h5>
                          <p class="font-size-15 text-muted mt-3 mb-0">
                            No necesitas tener un servidor dedicado, el sistema trabaja y
                            administra tu red sin importar en qué parte del mundo te
                            encuentres.
                          </p>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="testi-contain text-center">
                          <h5 class="font-size-20 mt-4">Administración automática</h5>
                          <p class="font-size-15 text-muted mt-3 mb-0">
                            Encargate de instalar más servicios de internet a tus
                            clientes, ArgusBlack se encargará de la parte administrativa,
                            facturación, cortes automáticos, control de ancho de banda,
                            administración de reuso de internet, etc.
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- end carousel-inner -->
                  </div>
                  <!-- end review carousel -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container fluid -->
  </div>
</template>

<script>
import SistemaSrv from "@/services/SistemaSrv.js"
import CredencialSrv from "@/services/CredencialSrv.js"
import SesionSrv from "@/services/SesionSrv.js"
// import { VueRecaptcha } from 'vue-recaptcha'
import md5 from "md5"
import { version } from "../../package"
import { todoGetters, todoMethods } from "@/state/helpers"

export default {
  // components: { VueRecaptcha },
  data() {
    return {
      tokenExistente: localStorage.getItem("argusblack.token"),
      clave: "",
      identidad: "",
      type: "password",
      urlApi: "",
      nErroresLogin: 0,
      procesando: false,
      modoDes: "",
      visibleModo: false,

      // sitekey:'6Lc9ILYpAAAAAFrIjI3gcJ0uBN_f7SmxuBSV0t_v',
      // token_recaptcha: null,
      version: version
    }
  },
  computed: {
    ...todoGetters,
    anio: {
      get() {
        return this.$store.state.todo.anio
      },
    },
    googleClaveRecaptcha: function () {
      return this.$store.state.todo.google_clave_recaptcha
    },
  },
  mounted() {
    if (localStorage.getItem("argusblack.theme.mode") == "light") {
      document.body.removeAttribute("data-sidebar")
      document.body.removeAttribute("data-layout-mode")
    } else {
      document.body.setAttribute("data-sidebar", "dark")
      document.body.setAttribute("data-layout-mode", "dark")
    }
  },
  created: function () {
    var self = this

    self.modoDesarrollo()

    if(!self.uc){
      // integracion de recaptcha enterprice
      var script = document.createElement("script")
      script.id = "recaptcha"
      script.src = "https://www.google.com/recaptcha/enterprise.js?render=" + self.googleClaveRecaptcha
      // script.addEventListener('load', self.setLoadedPaypal)
      document.head.appendChild(script)
    }

    // Actualización del módulo inicial
    let modulo = this.$route.query.modulo
    if (this.existeModulo(modulo)) {
      sessionStorage.setItem("argusblack.modulo", modulo)
      localStorage.setItem("argusblack.modulo", modulo)
      this.actualizarModuloSeleccionado({ modulo: modulo })
    }
  },
  methods: {
    ...todoMethods,
    // onVerify (response) {
    //   var self = this
    //   self.token_recaptcha = response
    // },
    // onExpired () {
    //   iu.msg.error('Expiro el tiempo recarga la vista o recete el reCAPTCHA')
    //   console.log('Expired')
    // },
    abrir() {
      var self = this, token = null

      self.procesando = true

      grecaptcha.enterprise.ready(async () => {
        token = await grecaptcha.enterprise.execute(self.googleClaveRecaptcha, { action: "login" })

        let datos = {
          identidad: self.identidad,
          clave: md5(self.clave),
          token_recaptcha: token
        }

        CredencialSrv.autenticar(datos).then((response) => {
          let token = response.data.token
          let message = response.data.message
          SesionSrv.actualizarToken(token)
          self.credencialValida()

          if (message != null) iu.msg.warning(message, {width: '850px'})

          self.estadoEmpresa()
          self.estadoSuscripcion()

          document.head.removeChild(document.getElementById("recaptcha"))
        }).catch((error) => {
          self.procesando = false
          self.credencialInvalida()

          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = "No se pudo autenticar"
          }
          iu.msg.error(mensaje)
          console.log(error)

          if (error.status == 500) {
            self.nErroresLogin++
            if (self.nErroresLogin >= 5) {
              location.reload()
            }
          }
        })
      })
    },
    modoDesarrollo() {
      var self = this

      SistemaSrv.modo().then((response) => {
        self.modoDes = response.data
        if (self.modoDes != "production") self.visibleModo = true
        else self.visibleModo = false
      })
    },
    estadoEmpresa() {
      var self = this

      SistemaSrv.estadoEmpresa().then((response) => {
        self.actualizarEstadoEmpresa({ estado: response.data })
      }).catch((error) => {
        self.actualizarEstadoEmpresa({ estado: false })
      })
    },
    estadoSuscripcion() {
      var self = this

      SistemaSrv.estadoSuscripcion().then((response) => {
        self.actualizarEstadoSuscripcionArgusblack({ estado: response.data })
      }).catch((error) => {
        self.actualizarEstadoSuscripcionArgusblack({ estado: false })
      })
    }
  }
}
</script>

<style>
.text-center {
  text-align: center
}
</style>